import { v4 as uuidv4 } from 'uuid';
import { getKeycloak } from './auth.js';
import {
  getReadModelFromItemType,
  itemTypesByReadModel
} from './items/item-types';

export const authorizedFetch = (
  url,
  method = 'GET',
  body,
  signal,
  contentType = 'application/json'
) => {
  return new Promise((resolve, reject) => {
    const keycloak = getKeycloak();
    keycloak
      .updateToken()
      .success(() => {
        let failed = false;

        const data = {
          method,
          signal,
          headers: {
            Authorization: `Bearer ${getKeycloak().token}`,
            'Content-Type': contentType
          },
          body: body
        };

        if (contentType === 'application/json') {
          data.body = JSON.stringify(body);
        }

        if (contentType === 'multipart/form-data') {
          // When sending form data, let the browser set the content type and boundary or request will fail
          delete data.headers['Content-Type'];
        }

        fetch(url, data)
          .then(response => {
            if (!response.ok) {
              failed = true;
            }

            if (response.headers.get('content-type') === 'application/xml') {
              return response.text();
            }

            if (
              response.headers.get('content-type') ===
              'application/octet-stream'
            ) {
              return response.blob();
            }

            if (response.status === 204) {
              return response.statusText;
            }

            return response.json();
          })
          .then(response => {
            if (failed) {
              reject(response);
            } else {
              resolve(response);
            }
          })
          .catch(err => {
            reject(err);
          });
      })

      .error(keycloak.logout);
  });
};

export const generateNewId = () => `new-${uuidv4()}`;
export const isItemNew = id => id && id.indexOf('new-') === 0;

export const cleanDate = dateString => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const d = new Date(dateString);
  const dd = d.getDate();
  const mm = d.getMonth();
  const yyyy = d.getFullYear();
  return `${months[mm]} ${dd}, ${yyyy}`;
};

export const pickByKeyValue = (obj, key, value) => {
  if (obj.hasOwnProperty(key) && obj[key] === value) {
    return obj;
  }

  const keys = Object.keys(obj);
  for (let i in keys) {
    let nestedItem = obj[keys[i]];
    if (
      nestedItem &&
      typeof nestedItem === 'object' &&
      !Array.isArray(nestedItem)
    ) {
      let result = pickByKeyValue(nestedItem, key, value);
      if (result) return result;
    }
  }

  return null;
};

export const isValidImageUrl = url => {
  if (!url) {
    return false;
  }
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

export const dateFormatOptions = {
  month: 'short',
  year: 'numeric',
  day: 'numeric',
  minute: 'numeric',
  hour: 'numeric'
};

export const isThisHour = date => {
  const today = new Date();
  return today.getUTCHours() === date.getUTCHours() && isToday(date);
};

export const isToday = date => {
  const today = new Date();
  return (
    today.getUTCFullYear() === date.getUTCFullYear() &&
    today.getUTCMonth() === date.getUTCMonth() &&
    today.getUTCDate() === date.getUTCDate()
  );
};

export const isThisMonth = date => {
  const today = new Date();
  return (
    today.getUTCMonth() === date.getUTCMonth() &&
    today.getUTCDate() === date.getUTCDate()
  );
};

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  s = s.toLowerCase();
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getItemNames = (
  itemIds,
  setItemNameMap,
  addError,
  itemTypesToSearch = []
) => {
  let indicesToSearch;
  if (itemTypesToSearch.length > 0) {
    indicesToSearch = itemTypesToSearch
      .map(iT => getReadModelFromItemType(iT))
      .filter(rm => !!rm)
      .map(rm => `eventflow-${rm}`)
      .join(',');
  } else {
    indicesToSearch = Object.keys(itemTypesByReadModel)
      .map(k => `eventflow-${k}`)
      .join(',');
  }

  authorizedFetch(
    `/api/search/${indicesToSearch}/_search?ignore_unavailable=true`,
    'POST',
    {
      _source: ['id', 'name'],
      size: itemIds.length,
      query: {
        terms: {
          'id.keyword': itemIds
        }
      }
    }
  )
    .then(response => {
      let itemIdNameMap = {};
      response.hits.hits.reduce((acc, curr) => {
        acc[curr._source.id] = curr._source.name;
        return acc;
      }, itemIdNameMap);
      setItemNameMap(itemIdNameMap);
    })
    .catch(e => addError('Unable to get item names', JSON.stringify(e)));
};

export const getPageTypes = async () => {
  const res = await authorizedFetch('/api/Lookup/pagetypes', 'GET');

  let pageTypeMap = {};
  res.reduce((acc, curr) => {
    acc[curr.id] = curr.description;
    return acc;
  }, pageTypeMap);
  return pageTypeMap;
};

export const formatNumber = (
  value,
  locales = 'en-US',
  options = {
    maximumFractionDigits: 2
  }
) => {
  return new Intl.NumberFormat(locales, options).format(value);
};

export const formatCurrency = (
  value,
  locales = 'en-US',
  options = {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  }
) => {
  return new Intl.NumberFormat(locales, options).format(value);
};

export const getSourceInfo = async () => {
  const query = {
    _source: ['id', 'name', 'affiliateId'],
    size: 10000
  };

  const result = await authorizedFetch(
    'api/search/eventflow-trafficsourcereadmodel/_search',
    'POST',
    query
  );

  let sourceIdMap = {};
  result?.hits?.hits?.reduce((prev, curr) => {
    prev[curr._source.affiliateId] = {
      name: curr._source.name,
      id: curr._source.id
    };
    return prev;
  }, sourceIdMap);
  return sourceIdMap;
};

export const getItemInfo = async (itemIds, setItemInfoMap, addError) => {
  let indicesToSearch = Object.keys(itemTypesByReadModel)
    .map(k => `eventflow-${k}`)
    .join(',');

  try {
    let itemInfoMap = {};
    const response = await authorizedFetch(
      `/api/search/${indicesToSearch}/_search?ignore_unavailable=true`,
      'POST',
      {
        _source: [
          'id',
          'userSegments',
          'flowId',
          'pageId',
          'surveyId',
          'advertiserId',
          'campaignId',
          'adGroupId',
          'adCreativeId',
          'jobCampaignId',
          'jobAdGroupId',
          'jobAdCreativeId',
          'callCampaignId'
        ],
        size: itemIds.length,
        query: {
          terms: {
            'id.keyword': itemIds
          }
        }
      }
    );
    response.hits.hits.reduce((acc, curr) => {
      acc[curr._source.id] = {
        ...curr._source,
        type:
          itemTypesByReadModel[curr._type] ||
          itemTypesByReadModel[curr._index.replace('eventflow-', '')]
      };
      return acc;
    }, itemInfoMap);
    setItemInfoMap(itemInfoMap);
  } catch (error) {
    addError('Unable to get item info', JSON.stringify(error));
  }
};

export const getIntegrationItems = async (id, setIntegrations, addError) => {
  if (!isItemNew(id)) {
    try {
      const response = await authorizedFetch(
        'api/search/eventflow-webapiintegrationreadmodel/_search',
        'POST',
        {
          _source: ['id', 'name', 'method', 'urlTemplate', 'dateModified'],
          size: 200,
          sort: {
            dateModified: 'desc'
          },
          query: {
            term: {
              'parentReferenceId.keyword': id
            }
          }
        }
      );
      const integrations = response?.hits?.hits
        ? response.hits.hits.map(d => d._source)
        : [];
      setIntegrations(integrations);
    } catch (error) {
      addError('Unable to fetch integrations', JSON.stringify(error));
    }
  }
};

export const convertKeyValueToObject = keyPairs => {
  return [...keyPairs].reduce((data, pair) => {
    const key = pair.key;
    const value = pair.value;

    if (key === '') return data;
    return {
      ...data,
      [key]: value
    };
  }, {});
};

export const convertObjectToKeyValue = obj => {
  return Object.entries(obj).map(([key, value]) => ({
    id: uuidv4(),
    key,
    value
  }));
};

export const isValidJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getItemsList = async (
  itemType,
  page = 1,
  pageSize = 500,
  accumulator = []
) => {
  try {
    const response = await authorizedFetch(
      `/api/${itemType}?page=${page}&pageSize=${pageSize}`,
      'GET'
    );

    accumulator = accumulator.concat(response.items);

    if (response.pages > page) {
      return getItemsList(itemType, page + 1, pageSize, accumulator);
    } else {
      return accumulator;
    }
  } catch (e) {
    throw new Error(e);
  }
};

export const keyValueField = (k = '', v = '') => {
  return {
    id: uuidv4(),
    key: k,
    value: v
  };
};

export const validateIPAddress = ip => {
  if (!ip) return false;

  // Regex for IPv4 address
  const ipv4Regex = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;

  // Regex for IPv6 address
  const ipv6Regex = /^[0-9a-fA-F]{1,4}(:[0-9a-fA-F]{1,4}){7}$/;

  // Check if IP matches either IPv4 or IPv6 pattern
  if (ipv4Regex.test(ip)) {
    // Validate each octet of IPv4 address
    const parts = ip.split('.');
    for (const part of parts) {
      if (parseInt(part, 10) < 0 || parseInt(part, 10) > 255) {
        return false;
      }
    }
    return 'IPv4';
  } else if (ipv6Regex.test(ip)) {
    return 'IPv6';
  } else {
    return false;
  }
};

export const getVerticals = async () => {
  const response = await authorizedFetch('/api/vertical/getAll', 'GET');
  return response;
};

export const getSubVerticals = async () => {
  const response = await authorizedFetch(
    '/api/vertical/getAllSubVerticals',
    'GET'
  );
  return response;
};

export const getSubVerticalNames = (ids, setItemNameMap, addError) => {
  authorizedFetch('/api/vertical/getAllSubVerticals', 'GET')
    .then(response => {
      const subVerticalNames = {};
      response.forEach(subVertical => {
        if (ids.includes(subVertical.id)) {
          subVerticalNames[subVertical.id] = subVertical.name;
        }
      });

      setItemNameMap(subVerticalNames);
    })

    .catch(e => addError('Unable to get subvertical names', JSON.stringify(e)));
};
