import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../items/item.actions';
import { getCapValidationMessage } from '../campaign-utils.js';
import { validateEpcOverride } from '../epc-override';

export const receiveCampaign = createAction('receiveCampaign');
export const setCampaignIsBusy = createAction('setCampaignIsBusy');
export const modifyCampaign = createAction('modifyCampaign');
export const closeCampaign = createAction('closeCampaign');

const campaignModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.CAMPAIGN,
  flowIds: [],
  capStrategy: 'none',
  lookBackWindowInDays: 7
});

export const getItem = getItemInternal(
  campaignModel,
  receiveCampaign,
  setCampaignIsBusy
);

export const saveItem = saveItemInternal(
  modifyCampaign,
  item => {
    const overrideError = validateEpcOverride(item);
    const converMappingErrors = conversionMappingValidation(
      item.conversionMappings
    );

    if (overrideError) return overrideError;
    if (converMappingErrors) return converMappingErrors;

    if (!item.advertiserId) {
      return 'Advertiser is required.';
    }

    if (!item.goal) {
      return 'Campaign Goal is required.';
    }

    if (
      !item.campaignType ||
      item.campaignType === 'wall' ||
      item.campaignType === 'inflow'
    ) {
      return 'Campaign Type is required.';
    }

    if (!item.lookBackWindowInDays) {
      return 'Conversion Lookback is required.';
    }

    if (item.lookBackWindowInDays < 1 || item.lookBackWindowInDays > 31) {
      return 'Conversion Lookback value must be between 1 and 31 days.';
    }

    if (!item.verticalId) {
      return 'Vertical is required.';
    }

    if (!item.subVerticalId) {
      return 'Subvertical is required.';
    }

    return getCapValidationMessage({
      dailyCap: item.dailyConversionCap,
      monthlyCap: item.monthlyConversionCap,
      overallCap: item.overallConversionCap
    });
  },
  itemCopy => {
    // delete temporary properties
    delete itemCopy.epcOverrideChecked;
    // delete adGroup collection since it's ignored anyways on campaign save
    delete itemCopy.adGroups;
  }
);

// Validate conversion mapping
const conversionMappingValidation = conversionMappingValidation => {
  if (conversionMappingValidation && conversionMappingValidation.length > 0) {
    let errorMessage = '';
    conversionMappingValidation.forEach(cm => {
      if (!cm.tuneOfferId) {
        errorMessage = 'Tune Offer ID is required';
        return;
      }
      if (!cm.conversionTypeIntegrationId) {
        errorMessage = 'Event ID (Goal ID) is required';
      }
      if (!cm.displayName) {
        errorMessage = 'Event Name is required';
      }
    });

    return errorMessage;
  }
};
